import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DefaultContainer from "../components/DefaultContainer";
import { insertOTP, validateOTP } from "../services/Auth";
import DefaultHeader from "../components/DefaultHeader";
import DefaultTextField from "../components/DefaultTextField";
import { userLogin } from "../config/redux/actions/userActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ls from "local-storage";
import Toast from "../components/Snackbar";

const VerifyOTPScreen = ({ userLogin }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [counter, setCounter] = useState(30);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState(0);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    timer();
    setPhone(location.state.phone);
  }, [location.state]);
  async function handleOTPChange(x) {
    setOtp(x);

    // if (x.length === 4) {
    //   await verifyOTP();
    // }
  }
  async function resendOTP() {
    await insertOTP({ phone: phone }).then((response) => {
      if (response.success === 1 && response.waStatus === true) {
        timer();
      }

      setCounter(30)
    });
  }
  async function verifyOTP() {
    setOpenBackdrop(true);

    var data = {
      phone: phone,
      otp: otp,
    };

    await validateOTP(JSON.stringify(data)).then((response) => {
      // kasih roast untuk response ini
      if (response.success === 1) {
        setIsOpen(true);
        setMsg(response.msg);
        setStatus(response.success);

        const userDetail = response.userDetail;
        const userToken = response.token;

        if (Object.keys(userDetail).length === 0) {
          setTimeout(() => {
            navigate("/complete-profile", {
              replace: true,
              state: {
                phone: location.state.phone,
                to: location.state.to,
                data: data
              },
            });
          }, 1000);
        } else {
          userLogin(userDetail);
          setTimeout(() => {
            navigate(location.state.to, { replace: true });
          }, 1000);
          // set token disini
          if (userToken) {
            ls.set("sfToken", userToken);
          }
        }
      } else {
        setIsOpen(true);
        setMsg(response.msg);
        setStatus(response.success);
        setTimeout(() => {
          setIsOpen(false);
        }, 100);
      }
      setOpenBackdrop(false);
    });
  }
  function timer() {
    if (counter > 0) {
      setInterval(() => {
        setCounter((counter) => counter - 1);
      }, 1000);
    }
  }

  return (
    <DefaultContainer updateDimension={true}>
      <Toast isOpen={isOpen} message={msg} status={status} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box>
        <Grid style={{ margin: 15 }}>
          <DefaultHeader title="Verifikasi OTP" />
          <Grid style={{ marginTop: 15 }}>
            <Grid container>
              <Grid item>
                <Typography>Kami telah mengirimkan OTP melalui WhatsApp {phone}</Typography>
              </Grid>
              <Grid item style={{ marginTop: 15 }} xs={12}>
                <DefaultTextField
                  label="OTP"
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  autoFocus
                  value={otp}
                  onChange={(e) => {
                    handleOTPChange(e.target.value);
                  }}
                  type={"tel"}
                />
              </Grid>
              <Grid item style={{ marginTop: 60 }} xs={12}>
                Belum terima OTP?
              </Grid>
              <Grid item style={{ marginTop: 5 }} xs={12}>
                <Button
                  title="start timer"
                  disabled={counter <= 0 ? false : true}
                  onClick={(e) => {
                    resendOTP();
                  }}
                >
                  {counter <= 0
                    ? "Kirim sekarang"
                    : " kirim ulang dalam " + counter + " detik"}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  style={{ color: "white", marginTop: 15, padding: 10 }}
                  onClick={async (e) => {
                    await verifyOTP();
                  }}
                >
                  Verifikasi
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </DefaultContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userLogin: (userDetail) => {
      dispatch(userLogin(userDetail));
    },
  };
};

VerifyOTPScreen.propTypes = {
  userLogin: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyOTPScreen);
