import {
  Box,
  Button,
  Fab,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import ls from "local-storage";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getVariantByID } from "../services/Products";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import MoneyFormat from "../components/MoneyFormat";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import { Checkbox } from "@material-ui/core";
import { grey } from "@mui/material/colors";
import DefaultContainer from "../components/DefaultContainer";
import { addToCart } from "../config/redux/actions/cartActions";
import { connect } from "react-redux";
import DefaultTextField from "../components/DefaultTextField";
import Toast from "../components/Snackbar";
import SkeletonLoader from "../components/SkeletonLoader";
import Confirmation from "../components/Confirmation";
import Warning from "../components/Warning";
import InventoryIcon from '@mui/icons-material/Inventory';
import { PriorityHigh } from "@mui/icons-material";

const ProductDetailsScreen = ({ addToCart, isSmartWaiter }) => {
  const location = useLocation();
  const navigate = useNavigate();
  // const [productDetails, setProductDetails] = useState([]);
  const [productDetails, setProductDetails] = useState("");
  const [variants, setVariants] = useState([]);
  const [qty, setQty] = useState(1);
  const [notes, setNotes] = useState("");
  const [price, setPrice] = useState(0);
  const [variantsList, setVariantsList] = useState([]);
  // const [variantRadio, setVariantRadio] = useState([]);
  // const [variantCheckbox, setVariantCheckbox] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [reqVariants, setReqVariants] = useState(0);
  const [msg, setMsg] = useState("");
  const [msgDialog, setMsgDialog] = useState("");
  const [status, setStatus] = useState(0);
  const [loading, setLoading] = useState(true);

  const [reqVariantsCount, setReqVariantsCount] = useState(-1);
  const [singlePrice, setSinglePrice] = useState(0);
  const [isOpeningHour, setIsOpeningHour] = useState(false);

  const [msgToast, setMsgToast] = useState("");
  const hours = new Date().getHours();
  const minutes = new Date().getMinutes();
  const seconds = new Date().getSeconds();
  const [isProductEnabled, setIsProductEnabled] = useState(true)
  const [forButton, setForButton] = useState(0)
  const [image, setImage] = useState("")
  const [addToCartClick, setAddToCartClick] = useState(false)
  const [isVariants, setIsVariants] = useState(false)
  const [isVariantFetched, setIsVariantFetched] = useState(false)

  const disabledButton = {
    bgColor: "gray",
    disabled: true
  }

  const enabledButton = {
    bgColor: "#1fb0e6",
    disabled: false
  }

  const [buttonBehavior, setButtonBehavior] = useState(disabledButton)

  const timeNow = `${hours > 9 ? hours : "0" + hours}:${
    minutes > 9 ? minutes : "0" + minutes
  }:${seconds > 9 ? seconds : "0" + seconds}`;

  const allowOverrideStock = ls.get("sfPartner").allow_override_stock;
  const [stock, setStock] = useState(0);

  const imageLoaded = () => {
    setLoading(false);
  };

  function countTotalPrice(newQty) {
    let harga =
      productDetails.harga_diskon === "0"
        ? 
        Number(productDetails.harga)
        : Number(productDetails.harga_diskon);
    // variantRadio.map((item) => {
    //   variantsList.add(item);
    //   harga += Number(item.price);
    // });
    // variantCheckbox.map((item) => {
    //   variantsList.add(item);
    //   harga += Number(item.price);
    // });



    let exactHarga 
    
    if(singlePrice === 0 || singlePrice === harga){
      exactHarga = harga
    } else if(harga === undefined || harga !== singlePrice){
      exactHarga = productDetails.harga_diskon
    } 

    setPrice(exactHarga);
    setSinglePrice(exactHarga);
    // if (qty === 1) {
    //   total = harga * qty
    //   setTotalPrice(total);
    // } else {
    //   total = harga * (qty + 1)
    //   setTotalPrice(total);
    // }
  }

  function handleAddQty() {
    if (qty < Number(productDetails.stock)) {
      setQty(qty + 1);
      setPrice(singlePrice * (qty + 1));

      // update qty variant yang terpilih juga jika ada variant nya
      let list = variantsList;
      if (list.length > 0) {
        list.forEach((item) => {
          item.data_variant.forEach((val) => {
            val.qty = qty + 1;
          });
        });
      }

      setVariantsList(list);
    }
  }

  function handleReduceQty() {
    if (qty > 1) {
      setQty(qty - 1);
      setPrice(singlePrice * (qty - 1));

      // update qty variant yang terpilih juga jika ada variant nya
      let list = variantsList;
      if (list.length > 0) {
        list.forEach((item) => {
          item.data_variant.forEach((val) => {
            val.qty = qty - 1;
          });
        });
      }

      setVariantsList(list);
    }
  }

  // const style = {
  //   marginLeft: 15,
  //   top: isOpeningHour ? 15 : 58.02,
  //   right: "auto",
  //   position: "sticky",
  //   color: "black",
  //   backgroundColor: "#fff",
  //   zIndex: 10,
  //   borderRadius: '50%'
  // };

  async function fetchVariants(productID) {
    await getVariantByID(productID).then((response) => {
      // const dataVariants = response["variants"];
      // setVariants(dataVariants ?? []);
      setVariants(response["variants"] ?? []);

      setForButton(response["variants"].length)

      if(response.menu_data.enabled !== "1"){
        setIsProductEnabled(false)
      }

      let i = reqVariants;

      // if (dataVariants.length > 0) {
      if (response["variants"].length > 0) {
        // dataVariants.forEach((variant) => {
          response["variants"].forEach((variant) => {
          if (
            variant.variant_group_type === "0" ||
            variant.variant_group_type === 0
          ) {
            i += 1;
          }
        });
      }

      setReqVariants(i);

      if(isVariantFetched === false){
        setIsVariantFetched(true)
      }
    });
  }

  function back() {
    if(location.state.dataFrom === "smartWaiter"){
      navigate("/cart")
    }else{
      navigate("/order");
    }

    if(location.state.dataFrom === "order"){
      navigate("/order", {
        state: {categoryIndex: location.state.categoryIndex}
      })
    }


  }

  const [rendererCount, setRendererCount] = useState(0) 

  useEffect(() => {

    if(rendererCount === 0){
      setProductDetails(location.state.details);
      setImage(location.state.details.img_data)
      setStock(parseInt(location.state.details.stock));
    }

    if(rendererCount === 1){
      if(productDetails) {
        countTotalPrice();
      }
    }

    if (location.state.details.is_variant === "1" && !isVariantFetched) {
      setIsVariants(true)
      fetchVariants(location.state.details.id);
    }
    // if(reqVariants === 0 && reqVariantsCount < 1){
    //   } else {
    //     setReqVariantsCount(0)
    //   }
    // }

    if (ls.get("sfOpenHour") && ls.get("sfOpenHour") < timeNow &&
    timeNow > ls.get("sfCloseHour")
    ) {
      setIsOpeningHour(false);
      setMsgToast(
        "Mohon maaf cafe sudah tutup, silahkan coba kembali besok"
      );
      return
  }
  if((ls.get("sfPartner").is_temporary_close==="1")){
    setIsOpeningHour(false)
    setMsgToast ("Mohon maaf cafe sedang tutup sementara")
    return
  }

  let buttonBehaviorVar = disabledButton

  if(ls.get("sfOpenHour") && ls.get("sfOpenHour") > timeNow){
    setIsOpeningHour(false);
    setMsgToast("Mohon maaf cafe belum buka");
    setButtonBehavior(disabledButton)
    return
  }

  if (ls.get("sfShift") === "1") {
    if (ls.get("sfOpenHour") && ls.get("sfOpenHour") < timeNow && timeNow < ls.get("sfCloseHour")) {
      setIsOpeningHour(true);
      buttonBehaviorVar = enabledButton
      // setButtonBehavior(enabledButton)
    }
  } else {
    if (ls.get("sfOpenHour") && ls.get("sfOpenHour") < timeNow &&
    timeNow > ls.get("sfLastOrder") && timeNow < ls.get("sfCloseHour")
    ) {
      setIsOpeningHour(false);
      setMsgToast(
        "Mohon maaf waktu pemesanan anda melebihi waktu last order, silahkan coba kembali besok"
        );
        setButtonBehavior(disabledButton)
      return
    } else {
      if(!ls.get("sfShift")){
        setMsgToast("Scan meja kembali untuk memesan!");
      }else{
        setMsgToast("Resto belum memulai shift. Mohon hubungi kasir");
      }
      setButtonBehavior(disabledButton)
      return
    }
  }

  setRendererCount(rendererCount + 1)

    //BILA WAJIB SEMUA SUDAH TERISI
    if(ls.get("sfPartner").open_bill !== "1" && allowOverrideStock !== "1"){
      if(reqVariants === 0 && stock > 0){
        setButtonBehavior(enabledButton)
        return
      }else{
        // setButtonBehavior(disabledButton)
        buttonBehaviorVar = disabledButton
      }
      
      if(reqVariants !== 0 && stock < 1){
        setButtonBehavior(disabledButton)
        return
      }
      
      
      if ((reqVariants > 0 && reqVariantsCount !== reqVariants) || stock < 1){
        // setButtonBehavior(disabledButton)
        buttonBehaviorVar = disabledButton
        // return
      }else{
        // setButtonBehavior(enabledButton)
        buttonBehaviorVar = enabledButton
        // return
      }
      
      setButtonBehavior(buttonBehaviorVar)
      return
    }
    else {      
      if(isVariants || location.state.details.is_variant === "1"){
        if(isVariantFetched) {
          if(reqVariants > 0){
            if (reqVariantsCount !== reqVariants){
              setButtonBehavior(disabledButton)
              return
            } else {
              setButtonBehavior(enabledButton)
              return
            }
          } else {
            setButtonBehavior(enabledButton)
            return
          }
        } else {
          setButtonBehavior(disabledButton)
          return
        }
      }
      else{
        setButtonBehavior(enabledButton)
        return
      }
    }

  }, [location.state, productDetails, msgToast, reqVariants, reqVariantsCount, isVariantFetched]);

  const handleChange = (e, idx, idGroup) => {
    let list = [...variantsList];

    const selectedGroupVariant = variants[idx];
    const selectedVariant = variants[idx]["variant"].filter(
      (item) => item.id === e.target.value
    )[0];
    let variant;
    let totalPriceVariant = 0;

    let selectedOptional = list.filter(
      (item) => item.id_variant === idGroup && item.type == 1
    )[0];
      
    if (selectedOptional) {
      // cek juga apakah selected variant sudah ada didalam selectedOptional
      let isSelectedVarExist = selectedOptional.data_variant.filter(
        (item) => item.id === selectedVariant.id
      )[0];
      let currVariantExist;

      if (isSelectedVarExist) {
        currVariantExist = selectedOptional.data_variant.filter(
          (item) => item.id !== selectedVariant.id
        );

        // ini kalau currVariantExist.length nya = 0
        if (currVariantExist.length < 1) {
          let currVariant = list.filter((item) => item.id_variant !== idGroup);
          list = [...currVariant];
        } else {
          selectedOptional.data_variant = currVariantExist;

          variant = {
            name: selectedGroupVariant.variant_group_name,
            id_variant: selectedGroupVariant.variant_group_id,
            type: selectedGroupVariant.variant_group_type,
            data_variant: selectedOptional.data_variant,
          };
        }
      } else {
        // ini kalau currVariantExist.length nya lebih dari 0
        variant = {
          name: selectedGroupVariant.variant_group_name,
          id_variant: selectedGroupVariant.variant_group_id,
          type: selectedGroupVariant.variant_group_type,
          data_variant: [
            ...selectedOptional.data_variant,
            {
              id: selectedVariant.id,
              name: selectedVariant.name,
              stock: selectedVariant.stock,
              qty: qty,
              price: selectedVariant.price,
            },
          ],
        };
      }
    } else {
      variant = {
        name: selectedGroupVariant.variant_group_name,
        id_variant: selectedGroupVariant.variant_group_id,
        type: selectedGroupVariant.variant_group_type,
        data_variant: [
          {
            id: selectedVariant.id,
            name: selectedVariant.name,
            stock: selectedVariant.stock,
            qty: qty,
            price: selectedVariant.price,
          },
        ],
      };
    }

    if (list.length > 0) {
      list = list.filter((item) => item.id_variant !== idGroup);
    }

    let newVariantsList;
    if (variant) {
      newVariantsList = [...list, variant];
    } else {
      newVariantsList = [...list];
    }

    let reqVarCount = 0;  
    if (newVariantsList.length > 0) {
      newVariantsList.forEach((val) => {
        if (val.type === "0" || val.type === 0) {
          reqVarCount += 1;
        }

        val.data_variant.forEach((item) => {
          totalPriceVariant += parseInt(item.price) * qty;
        });
      });
    } 

    let exactHarga 

    if(productDetails.harga_diskon === "0"){
        exactHarga = productDetails.harga
    } else {
      exactHarga = productDetails.harga_diskon 
    }

    let itemPrice = parseInt(exactHarga) + totalPriceVariant / qty;

    setSinglePrice(itemPrice);
    setPrice(itemPrice * qty);
    setReqVariantsCount(reqVarCount);
    setVariantsList(newVariantsList);
  };

  const handleAddToCart = () => {
    setAddToCartClick(true)
    let item = {
      partnerID: productDetails.id_partner,
      transactionType: ls.get("transactionType"),
      isSmartWaiter: location.state.dataFrom === "smartWaiter" ? 1 : isSmartWaiter,
      id: productDetails.id,
      name: productDetails.name,
      qty: qty,
      notes: notes,
      image:
        productDetails.thumbnail !== null || productDetails.thumbnail !== ""
          ? productDetails.img_data
          : productDetails.thumbnail,
      price: singlePrice,
      totalPrice: price,
      // variant: variantList,
      variant: variantsList,
      data: productDetails,
    };

    let msgValidate = [];

    item.variant.forEach((data) => {
      data.data_variant.forEach((val) => {
        if (parseInt(val.stock) < item.qty) {
          msgValidate.push(`varian ${val.name}`);
        }
      });
    });

    if (msgValidate.length > 0) {
      msgValidate = msgValidate.toString().replaceAll(",", ", ");
      setMsgDialog(msgValidate);
      handleClickOpen();
      return;
    } else {
      setMsg("Berhasil menambahkan ke cart");
      setIsOpen(true);
      setStatus(1);

      addToCart(item);
      setTimeout(() => {
        back();
      }, 1000);
    }
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOk = () => {
    setOpen(false);
  };

  const handleEmptyStock = () => {
    navigate("/order")
  }

  return (
        stock > 0 || ls.get("sfPartner").allow_override_stock === "1" ?
        <DefaultContainer bgColor={"#fafafa"}>
            <Toast isOpen={isOpen} message={msg} status={status} />
          <Confirmation
            open={open}
            onlyOk={true}
            onOk={handleClickOk}
            onClose={handleClose}
            title={"Peringatan"}
            confirmMsg={`Stock ${msgDialog} tidak mencukupi.`}
          />
          <Box style={{ backgroundColor: grey[50], position: "relative" }}>
            {isOpeningHour === false && <Warning msg={msgToast} />}
          <div>
            <Fab
              aria-label="add"
              style={{
                marginLeft: 15,
                top: isOpeningHour ? 15 : 58.02,
                right: "auto",
                position: "sticky",
                color: "black",
                backgroundColor: "#fff",
                zIndex: 10,
                borderRadius: "50%",
              }}
              size={"small"}
              onClick={(e) => {
                back();
              }}
            >
              <ArrowBackOutlinedIcon />
            </Fab>
            {productDetails.img_data ? (
              <div
                style={{
                  marginTop: "-40px" ,
                }}
              >
                <div style={{ display: loading ? "block" : "none" }}>
                  <SkeletonLoader type="productImage" />
                </div>
                <div
                  style={{
                    display: loading ? "none" : "block",
                    width: "100%",
                    height: 300,
                    overflow: "hidden",
                  }}
                >
                  <img
                    key={productDetails.img_data}
                    src={image}
                    alt="product"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                    onLoad={setTimeout(()=>{
                      setLoading(false)
                    }, 250)}
                    onError={() => {
                        setImage("https://www.rabrotech.com/upload/default/image-not-found.png");
                    }}
                  />
                </div>
              </div>
            ) : (
              <div
                className="test"
                style={{
                  width: "100%",
                  height: 400,
                  overflow: "hidden",
                  marginTop: "-40px",
                }}
              >
                <img
                  src="/assets/img/image-not-found.png"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  alt={"Product"}
                />
              </div>
            )}
            <Box style={{ backgroundColor: grey[50] }}>
              <Box
                style={{
                  marginLeft: 15,
                  marginRight: 15,
                  marginTop: 15,
                  paddingBottom: "60px",
                }}
              >
                <Grid item>
                  <Typography variant="h5" style={{ fontWeight: "500" }}>
                    {productDetails.nama}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>{productDetails.Deskripsi}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h5">
                    {" "}
                    { 
                      productDetails.harga_diskon !== "0" ?
                      <div>
                        <span style={{textDecoration: 'line-through', fontSize: "18px", color: "gray"}}>
                          <MoneyFormat value={productDetails.harga} />
                        </span> 
                      </div>
                      :
                      <div></div>
                    }
                    <MoneyFormat value={price} />
                  </Typography>
                </Grid>
                <Grid container>
                  {variants !== null ? (
                    variants &&
                    variants.map((k, i) => {
                      return (
                        <Grid
                          key={variants[i].variant_group_id}
                          style={{ marginTop: 15, width: "100%"}}
                        >
                          <FormControl style={{ width: "100%" }} >
                            <FormLabel style={{overflowWrap:"break-word", maxWidth:sessionStorage.getItem("comparison") < 1  ? sessionStorage.getItem("width")*0.9 : 420}} id="demo-radio-buttons-group-label">
                              {variants[i].variant_group_name +
                                (variants[i].variant_group_type === "0"
                                  ? " (Wajib pilih 1)"
                                  : " (Opsional)")}
                            </FormLabel>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue=""
                              name="radio-buttons-group"
                              onChange={(e) => {
                                handleChange(e, i, k.variant_group_id)
                              }
                              }
                            >
                              {variants[i].variant &&
                                variants[i].variant.map((l, j) => {
                                  return (
                                    <Grid
                                      style={{ marginTop: 10}}
                                      container
                                      item
                                      key={variants[i].variant[j].id}
                                      alignItems="center"
                                    >
                                      <Grid item xs={9}>
                                        <FormControlLabel
                                          value={variants[i].variant[j].id}
                                          control={
                                            variants[i].variant_group_type ===
                                            "0" ? (
                                              <Radio
                                                disabled={
                                                  variants[i].variant[j].stock <=
                                                  "0"
                                                    ? true
                                                    : false
                                                }
                                                label={
                                                  <Typography style={{overflowWrap:"break-word", maxWidth:sessionStorage.getItem("comparison") < 1  ? sessionStorage.getItem("width")*0.9 : 420}}>
                                                    {variants[i].variant[j].name}
                                                  </Typography>}
                                                />
                                            ) : (
                                                <Checkbox
                                                  disabled={
                                                    variants[i].variant[j].stock <=
                                                    "0"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) =>
                                                    {
                                                      handleChange(
                                                        e,
                                                        i,
                                                        k.variant_group_id,
                                                        k.variant_group_type
                                                      )
                                                    }
                                                  }
                                                />
                                            )
                                          }
                                          label={
                                            <Typography style={{overflowWrap:"break-word", maxWidth:sessionStorage.getItem("comparison") < 1  ? sessionStorage.getItem("width")*0.9 : 420}}>
                                              {variants[i].variant[j].name}
                                            </Typography>}
                                        />
                                        {variants[i].variant[j].stock <= "0" && (
                                          <span style={{ color: "red" }}>
                                            stok habis
                                          </span>
                                        )}
                                      </Grid>
                                      {/* <Grid item xs={7} backgroundColor="red">
                                    <Typography>
                                      {variants[i].variant[j].name}
                                    </Typography>
                                  </Grid> */}
                                      <Grid item xs={3} textAlign="right">
                                        {variants[i].variant[j].price === "0" ? (
                                          <div></div>
                                        ) : (
                                          <MoneyFormat
                                            value={variants[i].variant[j].price}
                                          />
                                        )}
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                            </RadioGroup>{" "}
                          </FormControl>
                        </Grid>
                      );
                    })
                  ) : (
                    <div></div>
                  )}
                </Grid>
                <Grid item xs={12} style={{ marginTop: 15, marginBottom: 30 }}>
                  <Box component="form" sx={{}} noValidate autoComplete="off">
                    <DefaultTextField
                      label="Catatan"
                      id="outlined-basic"
                      variant="outlined"
                      value={notes}
                      onChange={(e) => {
                        setNotes(e.target.value);
                      }}
                    />
                  </Box>
                </Grid>
              </Box>
            </Box>
            <Grid
              item
              container
              style={{
                bottom: 0,
                paddingRight: 15,
                paddingTop: 15,
                paddingBottom: 15,
                position: "fixed",
                backgroundColor: "white",
                zIndex: 2,
                maxWidth: 480,
              }}
            >
              <Grid item container xs={5} alignItems={"center"}>
                <Button
                  onClick={(e) => {
                    handleReduceQty();
                  }}
                >
                  <RemoveCircleOutlineRoundedIcon fontSize="large" />
                </Button>
                <Typography>{qty}</Typography>
                <Button
                  onClick={(e) => {
                    handleAddQty();
                  }}
                >
                  <AddCircleOutlineRoundedIcon fontSize="large" />
                </Button>
              </Grid>
              <Grid item xs={7}>
                <Button
                  fullWidth
                  variant="contained"
                  style={{
                    fontWeight: "bold",
                    color: "white",
                    borderRadius: 10,
                    background: buttonBehavior.disabled === false && addToCartClick === false ? buttonBehavior.bgColor : "gray"
                  }}
                  onClick={(e) => {
                    handleAddToCart();
                  }}
                  disabled={
                    buttonBehavior.disabled || addToCartClick
                  }
                  >
                  <Typography style={{ 
                    fontWeight: "bold", 
                    paddingRight: 5, 
                    paddingLeft: 5, 
                    paddingTop: (reqVariants > 0 && variants.length === 1) || variants.length === 0 ? 5 : "12px", 
                    paddingBottom: (reqVariants > 0 && variants.length === 1) || variants.length === 0 ? 5 : "12px"   
                    }}>
                    Tambah ke Cart
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </div> 
          </Box>
        </DefaultContainer> :
        <DefaultContainer bgColor={"#fafafa"} loading={true}>
          <div style={{
            height:"100vh",
            backgroundColor: "#1976D2"
          }}>
            <div style={{
            height:"100%",                            
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            color: "#fff",
            flexWrap: "wrap",
          }}>
              <div style={{
                  display: "flex",
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexWrap: "wrap",
                  width: "70%",
              }}>
                <PriorityHigh
                    style={{
                        fontSize: 90,
                        width: "100%",
                        marginBottom: "1rem"
                      }}
                />
                <Typography                   
                  style={{
                    fontSize: 40,
                    fontWeight: 500,
                    marginTop: 10,
                    textAlign: "center",
                    marginBottom: "2rem"
                }}>
                    Stok Habis
                </Typography>
                <Button
                  fullWidth
                  variant="outlined"
                  color="white"
                  onClick={
                    () => handleEmptyStock()
                  }
                >
                  <Typography
                      style={{ padding: 3 }}
                  >
                      Belanja Kembali
                  </Typography>
              </Button>
              </div>
            </div>
          </div>  
        </DefaultContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    cartData: state.cartData.filter(
      (data) => data.partnerID === ls.get("partnerID")
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (
      item,
      addToast,
      quantityCount,
      selectedProductColor,
      selectedProductSize
    ) => {
      dispatch(
        addToCart(
          item,
          addToast,
          quantityCount,
          selectedProductColor,
          selectedProductSize
        )
      );
    },
  };
};

ProductDetailsScreen.propTypes = {
  addToCart: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductDetailsScreen);
